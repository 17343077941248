import { Entry } from './entities/Entry'
import { EthercatDevice } from '/@/shared/entities/EthercatDevice'
import { Description, Entry as EntryData } from '/@/shared/importers/interfaces'
import md5 from 'md5'

export function empty(obj: any) {
  return obj === null || obj === undefined || obj === '' || (Array.isArray(obj) && obj.length === 0)
}

/**
 * Generates universally unique identifier
 * @returns UUID
 */
export function uuid(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0
    const v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export function portalUrl(path: string = '') {
  const portalUrl = import.meta.env.VITE_PORTAL_URL

  if (!portalUrl) {
    throw new Error('VITE_PORTAL_URL is not set')
  }

  if (!path.startsWith('/')) {
    path = '/' + path
  }

  return `${removeTrailingSlashes(portalUrl)}${path}`
}

export function removeTrailingSlashes(str: string) {
  return str.replace(/\/+$/, '')
}

/**
 * Checks if string is blank, null or undefined
 * @param string string for checking
 * @returns true if string is blank, null or undefined, false otherwise
 */
export function isBlank(string: string): boolean {
  return !string || /^\s*$/.test(string)
}

/**
 * Downloads a file
 * @param fileName
 * @param data
 */
export function download(fileName: string, data: string): void {
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.style.display = 'none'
  const blob = new Blob([data], { type: 'octet/stream' })
  const url = URL.createObjectURL(blob)
  a.href = url
  if (isBlank(fileName)) {
    a.download = 'noname'
  } else {
    a.download = fileName
  }
  a.click()
  URL.revokeObjectURL(url)
}

export function wait(amount: number) {
  return new Promise((r) => setTimeout(r, amount))
}

/**
 * Replaces # for 0 in string with hex number
 * @param hexNumberString string with hex number
 */
export function updateHexSymbol(hexNumberString: any): string {
  if (hexNumberString === undefined) {
    hexNumberString = 0
  }

  if (!isNaN(hexNumberString)) {
    hexNumberString = `#x${hexNumberString}`
  }

  return hexNumberString
}

export function convertNumberToHex(value: number) {
  return `#x${value.toString(16).padStart(8, '0')}`
}

export function convertHexToNumber(value: string) {
  const numeric = parseInt(value.toString().replace('#', '0'), 16)

  if (isNaN(numeric)) {
    throw new Error(`Cannot convert ${value} to number`)
  }

  return numeric
}

export function convertIndexToNumber(value: string) {
  if (value.includes('#')) {
    return convertHexToNumber(value)
  }

  return parseInt(value)
}

export function formatIndex(index: string | number | undefined) {
  if (!isNaN(parseInt(String(index)))) {
    index = Number(index)
  }

  if (typeof index === 'string') {
    return index.substr(2)
  }

  if (index === undefined) {
    index = Number(0).toString(16)
  }

  if (typeof index === 'number') {
    index = index.toString(16)
  }

  return `${index.toLowerCase().padStart(2, '0')}`
}

export async function readTextFile(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (event) => {
      if (event.target != null && typeof event.target.result === 'string') {
        resolve(event.target.result)
      } else {
        reject('empty file')
      }
    }
    reader.onerror = function (event) {
      reject(event)
    }

    reader.readAsText(file, 'utf8')
  })
}

export function convertToArray(value: any): any[] {
  if (!Array.isArray(value)) {
    value = [value]
  }

  return value
}

export function findDescription(device: EthercatDevice, descriptionData: Description[]) {
  let deviceFound = false

  const description = descriptionData.find((description) => {
    if (!areTheSameHexValue(description.type.productCode, device.type.productCode)) {
      return false
    }

    deviceFound = true

    if (areTheSameHexValue(description.type.revisionNo, device.type.revisionNo)) {
      return true
    }

    if (description.hideTypes && Array.isArray(description.hideTypes)) {
      return description.hideTypes.some((hideType) => hideType === device.type.revisionNo)
    }

    return false
  })

  if (!deviceFound) {
    throw new Error(`Description for device ${device.name} not found.`)
  }

  if (!description) {
    throw new Error(`Device ${device.name} has been found but there's no revision.`)
  }

  return description
}

export function changePdosNameToGroup(pdos: any = []) {
  if (!pdos) {
    return []
  }

  return convertToArray(pdos).map((pdo: any) => {
    pdo.group = pdo?.name || ''
    pdo.name = ''
    return pdo
  })
}

export function capitalize(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export function extractIfExists(
  item: { [key: string]: any },
  key: string,
  keyOverride: string | null = null,
  returnValue: any = {},
  capitalizeKey: boolean = true,
) {
  let itemKey = key

  if (keyOverride !== null && typeof keyOverride === 'string') {
    itemKey = keyOverride
  }

  itemKey = capitalizeKey ? capitalize(itemKey) : itemKey
  return item[key] ? { [itemKey]: item[key] } : returnValue
}

export function findEntry(entry: Entry, data: EntryData) {
  return haveTheSameIndexAndSubIndex(entry, data)
}

export function haveTheSameIndexAndSubIndex(entry: Entry, data?: EntryData) {
  if (!data) {
    return false
  }

  const entryMd5 = md5(String(convertHexToNumber(entry.index)) + String(entry.subIndex))
  const dataMd5 = md5(String(convertHexToNumber(data.index)) + String(data.subIndex))

  return entryMd5 === dataMd5
}

export function areTheSameHexValue(value1: string, value2: string) {
  return convertHexToNumber(value1) === convertHexToNumber(value2)
}

export function removeNumbersFromString(value: string) {
  return value.replace(/[0-9]/g, '')
}

export function cloneClass(instance: unknown) {
  return Object.assign(Object.create(Object.getPrototypeOf(instance)), instance)
}
