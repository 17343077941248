import { computed } from 'vue'
import { getModule } from 'vuex-module-decorators'
import AppModule from '/@/store/modules/app'
import EthercatModule from '/@/store/modules/ethercat'
import store from '/@/store'

export function useStore() {
  const ethercat = getModule(EthercatModule, store)
  const app = getModule(AppModule, store)

  const domains = computed(() => ethercat.domains)
  const hasDomains = computed(() => ethercat.hasDomains)

  return {
    domains,
    hasDomains,
    ethercat,
    app,
  }
}
