import Vue from 'vue'
import Vuex from 'vuex'
import Ethercat from './modules/ethercat'
import App from './modules/app'
import storage from './plugins/storage'

Vue.use(Vuex)

export interface State {
  app: App
  ethercat: Ethercat
}

const store = new Vuex.Store<State>({
  strict: process.env.NODE_ENV !== 'production',
  plugins: [storage],
  modules: {
    app: App,
    ethercat: Ethercat,
  },
})

export default store
