import { MutationPayload, Store as VuexStore } from 'vuex'
import { State } from '../index'
import Store from 'store'

const mutations = ['app/updateConnectionProperty', 'app/updateEthercatSettingsProperty']
const storageKey = 'vectioneer-ethercat'

const removeDuplicates = (payload: any, entries: Object[]) => {
  const keys = Object.keys(payload)

  return entries.filter((value: any) => {
    return !keys.some((key) => value.hasOwnProperty(key))
  })
}

const checkSaveMutation = ({ type, payload }: MutationPayload) => {
  if (!mutations.includes(type)) {
    return
  }

  const settings = Store.get(storageKey, {})
  let updates = settings[type]

  if (updates) {
    if (payload instanceof Object) {
      updates = removeDuplicates(payload, updates)
    }

    updates.push(payload)
  } else {
    updates = [payload]
  }

  settings[type] = updates
  Store.set(storageKey, settings)
}

const init = (vuexStore: VuexStore<State>) => {
  const settings = Store.get(storageKey, {})

  for (const key in settings) {
    const updates = settings[key]

    if (Array.isArray(updates)) {
      updates.forEach((update) => vuexStore.commit(key, update))
    }
  }
}

const storage = (vuexStore: VuexStore<State>) => {
  vuexStore.subscribe(checkSaveMutation)
  init(vuexStore)
}

export default storage
