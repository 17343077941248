import Vue from 'vue'

Vue.directive('focus', {
  inserted: function (el) {
    const input = el.querySelector('input')

    if (input) {
      Vue.nextTick(() => input.focus())
    }
  },
})
