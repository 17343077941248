import { NA } from '/@/shared/constants'
import { Type } from 'class-transformer'
import { LinkedParameter } from '/@/shared/entities/LinkedParameter'
import { Mapping } from '/@/shared/entities/Mapping'
import { uuid } from '/@/shared/utils'
import { Dir } from '/@/shared/entities/Dir'
import { Entry as EntryDataInterface } from '../importers/interfaces'
import Typeable from './shared/Typeable'
import { TypeSignature } from './enums/TypeSignature'

export class Entry extends Typeable {
  public readonly uuid = uuid()

  public index: string = '-1'
  public subIndex: number = 0

  public name: string = NA
  public group: string = ''
  public bitLength: number = 1
  public selected: boolean = false
  public type = TypeSignature.NOT_SET
  public dir: Dir = Dir.XX

  public gapLength: number = 0
  public gapName: string = ''

  @Type(() => LinkedParameter)
  public readonly linkedParameters: LinkedParameter[] = []

  @Type(() => Mapping)
  public readonly maps: Mapping[] = []

  public get hasGap() {
    return this.gapLength > 0
  }

  public get address(): string {
    return `${this.index.toLowerCase()}:${this.subIndex.toString(16).toLowerCase().padStart(2, '0')}`
  }

  constructor(data: EntryDataInterface, dir: Dir) {
    super(data)

    this.dir = dir
    this.index = data.index

    this.name = data.name
    this.group = data.group
    this.bitLength = data.bitLength

    // When type isn't set, default to BIT -- see issue: #https://git.vectioneer.com/tools/ethercat/ethercat-configurator/-/issues/84
    this.type =
      data.type !== undefined //
        ? data.type
        : TypeSignature.BIT

    if (data.subIndex) {
      this.subIndex = data.subIndex
    }

    if (data.gapLength) {
      this.gapLength = data.gapLength
    }

    if (data.gapName) {
      this.gapName = data.gapName
    }

    if (data.mapping) {
      this.maps = data.mapping.map((data) => new Mapping(data))
    }

    if (data.maps) {
      this.maps = data.maps.map((data) => new Mapping(data))
    }

    if (data.selected) {
      this.selected = data.selected
    }

    if (data.linkedParameters) {
      this.linkedParameters = data.linkedParameters.map((data) => LinkedParameter.fromData(data))
    }
  }

  public merge(data: EntryDataInterface) {
    if (!this.name.length) {
      this.name = data.name
    }

    if (!this.group.length) {
      this.group = data.group
    }

    if (this.type !== data.type) {
      this.type = data.type
    }

    if (this.bitLength !== data.bitLength) {
      this.bitLength = data.bitLength
    }

    return this
  }
}
