import {
  ETHERCAT_PROTOCOL,
  ETHERCAT_PORT,
  ETHERCAT_TIMEOUT,
  ETHERCAT_DEPLOY_BUS,
  ETHERCAT_SCAN_BUS,
  ETHERCAT_GET_CONFIG,
  ETHERCAT_GET_ESI_LIST,
} from '/@/shared/constants'
import { createHttpInstance } from './http'

export interface InterfaceEthercatConnectorSettings {
  protocol: 'http' | 'https'
  port: number
  timeout: number
  endpoints: {
    deployBus: string
    getConfig: string
    scanBus: string
    getEsiList: string
  }
}

const defaultSettings: InterfaceEthercatConnectorSettings = {
  protocol: ETHERCAT_PROTOCOL,
  port: ETHERCAT_PORT,
  timeout: ETHERCAT_TIMEOUT,
  endpoints: {
    deployBus: ETHERCAT_DEPLOY_BUS,
    getConfig: ETHERCAT_GET_CONFIG,
    scanBus: ETHERCAT_SCAN_BUS,
    getEsiList: ETHERCAT_GET_ESI_LIST,
  },
}

class EthercatConnector {
  private protocol: string
  private port: number
  private host: string
  private timeout: number
  private endpoints: typeof defaultSettings.endpoints

  public constructor(host: string, config: InterfaceEthercatConnectorSettings = defaultSettings) {
    config = Object.assign({}, defaultSettings, config)

    this.host = host
    this.port = config.port
    this.protocol = config.protocol
    this.timeout = config.timeout
    this.endpoints = config.endpoints
  }

  public buildConnectionUrl(path: string) {
    return this.baseUrl(path)
  }

  public async getData(path: string) {
    const response = await this.httpClient.get(path)

    if (!response.data.data) {
      throw new Error('Empty response')
    }

    return response.data
  }

  public async postData(path: string, data: Record<string, any>) {
    const response = await this.httpClient.post(path, data)
    return response.data
  }

  public getConfig() {
    return this.getData(this.endpoints.getConfig)
  }

  public scanBus() {
    return this.getData(this.endpoints.scanBus)
  }

  public deploy(data: Record<string, any>) {
    return this.postData(this.endpoints.deployBus, data)
  }

  public getEsiList(data: Record<string, any>) {
    return this.postData(this.endpoints.getEsiList, data)
  }

  private get baseUrl() {
    return (path: string = '') => {
      const url = new URL(`${this.protocol}://${this.host}`)
      url.port = this.port.toString()
      url.pathname = path

      return url.toString()
    }
  }

  private get httpClient() {
    return createHttpInstance(this.baseUrl(), this.timeout)
  }
}

export default EthercatConnector
