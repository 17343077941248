import { NA } from '/@/shared/constants'
import { Type } from '../importers/interfaces'

export class DeviceType {
  public readonly name: string
  public readonly productCode: string
  public readonly revisionNo: string

  constructor(data: Type = { name: NA, productCode: NA, revisionNo: NA }) {
    this.name = data.name
    this.productCode = data.productCode
    this.revisionNo = data.revisionNo
  }
}
