import Adapter from './Adapter'
import { readTextFile } from '/@/shared/utils'

class FileAdapter extends Adapter {
  async process(file: File) {
    this.content = await readTextFile(file)
    return await this.getDevices()
  }
}

export default FileAdapter
