import Vue from 'vue'
import connection from '/@/modals/connection/index.vue'
import deploy from '/@/modals/deploy/index.vue'
import devices from '/@/modals/devices/index.vue'
import parameters from '/@/modals/parameters/index.vue'
import settings from '/@/modals/settings/index.vue'

Vue.component('v-modal-connection', connection)
Vue.component('v-modal-deploy', deploy)
Vue.component('v-modal-devices', devices)
Vue.component('v-modal-parameters', parameters)
Vue.component('v-modal-settings', settings)
