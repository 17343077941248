import { spawn, Thread } from 'threads'
import { EthercatDevice } from '/@/shared/entities/EthercatDevice'
import DescriptionWorker from '/@/shared/workers/processDescriptions.ts?worker'
import { areTheSameHexValue } from './utils'
import { EsiSearchResultType } from './api'

export async function fetchDescriptions(searchResults: EsiSearchResultType[]) {
  if (!searchResults.length) {
    return
  }

  const descriptionWorker = await spawn(new DescriptionWorker())
  const descriptions = await descriptionWorker.process(searchResults)
  await Thread.terminate(descriptionWorker)

  return descriptions
}

export function findDeviceByType(type: { revisionNo: string; productCode: string }, devices: EthercatDevice[]) {
  return devices.find((device) => {
    return (
      areTheSameHexValue(device.type.revisionNo, type.revisionNo) &&
      areTheSameHexValue(device.type.productCode, type.productCode)
    )
  })
}

export function findDevicesByType(type: { revisionNo: string; productCode: string }, devices: EthercatDevice[]) {
  return devices.filter((device) => {
    return (
      areTheSameHexValue(device.type.revisionNo, type.revisionNo) &&
      areTheSameHexValue(device.type.productCode, type.productCode)
    )
  })
}
