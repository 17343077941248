import { computed, ref, watch } from 'vue'
import { ConnectionParameters } from '../ConnectionParameters'
import { NodeType } from '../actions/SearchMotorcortexTree'
import { sessionStatus } from '../entities/enums/SessionState'
import Motorcortex from '../motorcortex'
import SearchMotorcortexTree from '/@/shared/actions/SearchMotorcortexTree'
import { useFlits } from '@vectioneer/ui'
import { useStore } from './store'

const connection = ref<Motorcortex | null>(null)
const tree = ref<NodeType | null>(null)

export function useMotorcortex() {
  const flits = useFlits()
  const { app: appStore } = useStore()

  const connectionParameters = computed(() => appStore.connectionParameters)
  const isConnectionInErrorState = computed(() => appStore.isConnectionInErrorState)
  const isConnecting = computed(() => appStore.isConnecting)
  const canConnect = computed(() => connectionParameters.value.canConnect)
  const parameters = computed(() => connectionParameters.value)
  const hasTree = computed(() => tree.value !== null)

  const createConnection = async () => {
    try {
      connection.value = new Motorcortex(parameters.value, {
        on: {
          connectionFailed: (event) => {
            const connection = event.target as Motorcortex
            setTimeout(() => {
              connection.connect()
            }, 2500)
          },
          connected: (event) => {
            tree.value = (event.target as Motorcortex).getTree()
            appStore.setConnectionStatus(event.type as sessionStatus)
          },
        },
      })

      connection.value.on(['connecting', 'disconnected', 'connectionLost', 'disconnected'], (event) => {
        tree.value = null
        appStore.setConnectionStatus(event.type as sessionStatus)
      })

      await connection.value.connect()
    } catch (error) {
      if (import.meta.env.VITE_DEBUG) {
        //eslint-disable-next-line
        console.error(error)
      }

      let message = error

      if (typeof error === 'object') {
        message = error.text
      }

      flits.error(message)
      appStore.setConnectionStatus('error')
    }
  }

  const onConnectionParametersChanged = (parameters: ConnectionParameters) => {
    if (parameters.canConnect) {
      if (!connection.value) {
        createConnection()
      } else {
        connection.value.updateConnectionParameters(parameters)
      }
    }
  }

  const search = (query: string) => {
    return SearchMotorcortexTree.search(tree.value, query)?.at(0)
  }

  const connect = () => {
    connection.value?.connect()
  }

  watch(connectionParameters, onConnectionParametersChanged, { immediate: true, deep: true })

  return {
    hasTree,
    canConnect,
    isConnectionInErrorState,
    parameters,
    connect,
    tree,
    search,
    isConnecting,
  }
}
