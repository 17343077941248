import { intToHex } from '../utils/conversion'

function parseStringValueToInt(value: string) {
  if (value.startsWith('#x') && value.length !== 10) {
    return parseInt(value.slice(2), 16)
  }

  return parseInt(value, 10)
}

export default class HexId {
  private constructor(private id: string) {}

  public static make(value: null | number | string) {
    if (value === null) {
      return new HexId(intToHex(0))
    }

    if (typeof value === 'number') {
      return new HexId(intToHex(value))
    }

    return HexId.fromString(value)
  }

  public static fromString(value: string) {
    value = value.toLocaleLowerCase()

    if (value.startsWith('#x') && value.length === 10) {
      return new HexId(value)
    }

    const parsedValue = parseStringValueToInt(value)

    if (isNaN(parsedValue)) {
      throw new Error(`Cannot parse ${value} to a hex id.`)
    }

    return new HexId(intToHex(parsedValue))
  }

  public toString() {
    return this.id
  }
}
