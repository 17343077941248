import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages() {
  const locales = import.meta.glob('./locales/*.json', { as: 'raw', eager: true })
  const messages: Record<string, any> = {}

  for (const path in locales) {
    const matched = path.match(/([A-Za-z0-9-_]+)\./i)

    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = JSON.parse(locales[path] as unknown as string)
    }
  }

  return messages
}

const i18n = new VueI18n({
  locale: (import.meta.env.VITE_I18N_LOCALE as string) ?? 'en',
  fallbackLocale: (import.meta.env.VITE_I18N_FALLBACK_LOCALE as string) ?? 'en',
  messages: loadLocaleMessages(),
})

const $t = i18n.t.bind(i18n)
const $tc = i18n.tc.bind(i18n)

export { $t, $tc }

export default i18n
