import {
  ETHERCAT_PROTOCOL,
  ETHERCAT_PORT,
  ETHERCAT_SCAN_BUS,
  ETHERCAT_DEPLOY_BUS,
  ETHERCAT_GET_CONFIG,
  ETHERCAT_TIMEOUT,
  ETHERCAT_GET_ESI_LIST,
} from './constants'

class EthercatSettings {
  public protocol!: 'http' | 'https'
  public port!: number

  public endpoints!: {
    scanBus: string
    deployBus: string
    getConfig: string
    getEsiList: string
  }

  public timeout!: number

  public constructor() {
    this.reset()
  }

  public reset() {
    Object.assign(this, {
      protocol: ETHERCAT_PROTOCOL,
      port: ETHERCAT_PORT,
      endpoints: {
        scanBus: ETHERCAT_SCAN_BUS,
        deployBus: ETHERCAT_DEPLOY_BUS,
        getConfig: ETHERCAT_GET_CONFIG,
        getEsiList: ETHERCAT_GET_ESI_LIST,
      },
      timeout: ETHERCAT_TIMEOUT,
    })
  }
}

export default EthercatSettings
