import { EthercatDevice } from '/@/shared/entities/EthercatDevice'
import { uuid } from '/@/shared/utils'
import { Type } from 'class-transformer'
import Excludable from './shared/Excludable'

interface EthercatDomainInterface {
  name?: string
  devices?: EthercatDevice[]
  simulation?: boolean
  isNulldomain?: boolean
  frequencyDivider?: number
}

export class EthercatDomain extends Excludable {
  public readonly id = uuid()
  public name = ''
  public isNullDomain = false
  public isSimulated = false
  public frequencyDivider = 1

  @Type(() => EthercatDevice)
  public devices: EthercatDevice[] = []

  constructor({ name, simulation, devices, isNulldomain, frequencyDivider }: EthercatDomainInterface = {}) {
    super(...arguments)

    if (name) {
      this.name = name
    }

    if (simulation) {
      this.isSimulated = simulation
    }

    if (isNulldomain) {
      this.isNullDomain = true
    }

    this.frequencyDivider = frequencyDivider ?? 1

    if (devices) {
      if (!devices.some((device) => device instanceof EthercatDevice)) {
        devices = devices.map((device) => new EthercatDevice(device))
      }

      this.devices = devices
    }
  }

  public containsDevice(device: EthercatDevice) {
    return this.devices.some((domainDevice) => {
      return domainDevice === device
    })
  }
}
