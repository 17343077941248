import TopologyImporter from '/@/shared/importers/Topology'
import { EthercatDevice } from '/@/shared/entities/EthercatDevice'

class Adapter {
  protected content: string = ''

  public setContent(content: string) {
    this.content = content
    return this
  }

  public async getData() {
    if (!this.content.length) {
      throw new Error('Content cannot be empty.')
    }

    return await TopologyImporter.import(this.content)
  }

  public async getDevices() {
    const devices = await this.getData()
    return devices.map((data) => new EthercatDevice(data))
  }
}

export default Adapter
