import { Component } from 'vue-property-decorator'
import Base from '/@/shared/classes/base'
import ScanAdapter from '/@/shared/adapters/configuration/ScanAdapter'
import FileAdapter from '/@/shared/adapters/configuration/FileAdapter'
import { wait } from '/@/shared/utils'

@Component({
  components: {
    'v-me': () => import('./me/index.vue'),
  },
})
export default class App extends Base {
  private app = this.loadAppModule()

  declare $refs: {
    popover: any
  }

  private get isDebug(): boolean {
    return import.meta.env.VITE_DEBUG === 'true'
  }

  private get version(): string {
    return GIT_VERSION
  }

  private async shouldApplyConfiguration() {
    if (this.ethercat.hasDevices) {
      const confirm = await this.$dialog
        .okText('Yes')
        .cancelText('No')
        .confirm('This action will replace your current configuration, are you sure you want to proceed?')

      return confirm
    }

    return true
  }

  private async createNewConfiguration() {
    if (!(await this.shouldApplyConfiguration())) {
      return
    }

    this.ethercat.clearState(false)
  }

  private async fetchConfiguration() {
    if (!(await this.shouldApplyConfiguration())) {
      return
    }

    try {
      this.redirectToRoot()

      const domains = await new ScanAdapter().process(this.app.connectionParameters.host)

      this.ethercat.applyConfiguration({
        domains,
      })
    } catch (error) {
      this.$flits.error(error)
    }
  }

  private async loadConfiguration(event: InputEvent) {
    if (!(await this.shouldApplyConfiguration())) {
      return
    }

    const target = event.target as HTMLInputElement
    const file = (target.files as FileList).item(0)

    if (!file) {
      return
    }

    try {
      const domains = await new FileAdapter().process(file)

      this.ethercat.applyConfiguration({
        domains,
      })

      this.$refs.popover.hide()
    } catch (error) {
      this.$flits.error(error)
      throw error
    }
  }

  private async downloadConfiguration() {
    await this.ethercat.downloadConfiguration()
    await wait(200)
    this.$refs.popover.hide()
  }
}
