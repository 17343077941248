import { PORT_RECEIVE, PORT_RECEIVE_SECURE, PORT_REQUEST, PORT_REQUEST_SECURE } from '/@/shared/constants'

export class ConnectionParameters {
  public host: string = ''
  public secured: boolean = true
  public login: string = ''
  public password: string = ''

  private _source: string = ''
  private _requestPort: number = PORT_REQUEST_SECURE
  private _receivePort: number = PORT_RECEIVE_SECURE

  set source(value: string) {
    try {
      const url = new URL(value)
      value = url.host

      if (url.protocol.includes('https')) {
        this.secured = true
      }
    } catch (error) {}

    this._source = value

    if (value.indexOf(':') > -1) {
      const [host, ports] = value.split(':')
      const [request, receive] = ports.split('/')

      this.requestPort = Number(request)
      this.receivePort = Number(receive)
      this.host = host
    } else {
      this.host = value
    }
  }

  get url() {
    const protocol = this.secured ? 'https' : 'http'
    return `${protocol}://${this.host}`
  }

  get source() {
    return this._source
  }

  get defaultRequestPorts(): number[] {
    return [PORT_REQUEST, PORT_REQUEST_SECURE]
  }

  get defaultReceivePorts(): number[] {
    return [PORT_RECEIVE, PORT_RECEIVE_SECURE]
  }

  get requestPort() {
    if (this._requestPort && !this.defaultRequestPorts.includes(this._requestPort)) {
      return this._requestPort
    }

    return this.secured ? PORT_REQUEST_SECURE : PORT_REQUEST
  }

  set requestPort(value: number) {
    this._requestPort = value
  }

  get receivePort() {
    if (this._receivePort && !this.defaultReceivePorts.includes(this._receivePort)) {
      return this._receivePort
    }

    return this.secured ? PORT_RECEIVE_SECURE : PORT_RECEIVE
  }

  set receivePort(value: number) {
    this._receivePort = value
  }

  public get arePortsValid() {
    const ports = [this.requestPort, this.receivePort]
    return !ports.some((port) => isNaN(port) || port === undefined)
  }

  public get canScan() {
    return this.host.length > 0
  }

  public get canConnect() {
    return ![this.host].some((prop: string) => prop.length === 0)
  }
}
