import { DataSignature, isBitlengthValid, makeTypeString } from '../enums/DataSignature'
import { floatToUint32, createSizedBufferFrom, addLeadingZero } from '/@/shared/utils/conversion'
import { Buffer } from 'buffer'

export interface DataOptions {
  bitLength: number
  signature: DataSignature
}

export class Data {
  public value: string
  public signature: DataSignature
  public bitLength: number

  public constructor(value: string, options?: DataOptions) {
    this.value = value
    this.signature = options?.signature || DataSignature.OCTET_ARRAY
    this.bitLength =
      options?.bitLength !== undefined //
        ? options.bitLength
        : Math.round(this.value.length / 2) * 8

    if (!isBitlengthValid(this.signature, this.bitLength)) {
      throw new Error(`Invalid bit length for signature: ${this.signature}`)
    }
  }

  public get formattedValue() {
    return createSizedBufferFrom(this.value, this.bitLength).toString('hex')
  }

  public get displayValue() {
    return this.convertToSignatureType()
  }

  public get minimumBitLength() {
    // Determine the bit length of the data
    // which is a hex value
    return ((this.value.length + 1) << 2) & -8
  }

  public get datatype(): string {
    return makeTypeString(this.signature, this.bitLength)
  }

  public get isNumericType() {
    return [DataSignature.FLOAT, DataSignature.INT, DataSignature.UINT].includes(this.signature)
  }

  public convertToSignatureType() {
    const buffer = createSizedBufferFrom(this.value, this.bitLength)

    if (this.signature === DataSignature.INT) {
      return buffer.readIntLE(0, buffer.length)
    }

    if (this.signature === DataSignature.UINT) {
      return buffer.readUIntLE(0, buffer.length)
    }

    if (this.signature === DataSignature.FLOAT) {
      return parseFloat(buffer.readFloatLE().toPrecision(7))
    }

    return buffer.toString('hex')
  }

  public convertFromSignatureType(value: number | string): string {
    if (this.isNumericType && typeof value === 'number') {
      if (this.signature === DataSignature.FLOAT) {
        value = floatToUint32(value)
      }

      // https://surajsharma.net/blog/decimal-to-hexadecimal-javascript
      // parse negative hex
      return Buffer.from(addLeadingZero((value >>> 0).toString(16)), 'hex')
        .reverse()
        .toString('hex')
    }

    return value as string
  }
}
