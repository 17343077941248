import { parseStringPromise } from 'xml2js'
import { firstCharLowerCase, parseBooleans } from 'xml2js/lib/processors'
import { CoE, SoE } from '/@/shared/entities/StartupItems'
import { extractCoE, extractSoE } from './extractors'
import { parseIndex } from './processors'
import { convertToArray } from '../utils'

class StartupImporter {
  static async import(xmlData: string) {
    const result = await parseStringPromise(xmlData, {
      tagNameProcessors: [firstCharLowerCase],
      attrNameProcessors: [firstCharLowerCase],
      valueProcessors: [parseBooleans, parseIndex],
      attrValueProcessors: [parseBooleans],
      trim: false,
      explicitArray: false,
    })
    return this.formatStartups(result?.etherCATMailbox)
  }

  static formatStartups(mailbox: any): SoE[] | CoE[] {
    if (mailbox?.soE?.initCmds?.initCmd) {
      return convertToArray(mailbox.soE.initCmds.initCmd) //
        .map((startup: any) => new SoE({ ...extractSoE(startup) }))
    }

    if (mailbox?.coE?.initCmds?.initCmd) {
      return convertToArray(mailbox.coE.initCmds.initCmd) //
        .map((startup: any) => new CoE({ ...extractCoE(startup) }))
    }

    throw new Error('Failed to parse startup parameters file')
  }
}

export default StartupImporter
