import Adapter from './Adapter'
import { getEthercatConnector } from '/@/store/helpers'

class ScanAdapter extends Adapter {
  async process(host: string) {
    const response = await getEthercatConnector().getConfig()

    this.content = response.data
    this.files = response.files

    return await this.getDomains()
  }
}

export default ScanAdapter
