import ConfigurationAdapter from '/@/shared/adapters/device-list/ConfigurationAdapter'
import { readTextFile } from '/@/shared/utils'

class FileAdapter extends ConfigurationAdapter {
  async process(source: File) {
    this.content = await readTextFile(source)

    return await this.getDevices()
  }
}

export default FileAdapter
