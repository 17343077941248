// motorcortex connection constants
export const PORT_REQUEST = 5558
export const PORT_RECEIVE = 5557

export const PORT_REQUEST_SECURE = 5568
export const PORT_RECEIVE_SECURE = 5567

export const TIMEOUT_MS = 5000
export const QUEUE_LENGTH = 1000

// Ethercat connection constants
export const ETHERCAT_PROTOCOL = 'https'
export const ETHERCAT_PORT = 443
export const ETHERCAT_SCAN_BUS = '/services/ethercat/scan-bus'
export const ETHERCAT_DEPLOY_BUS = '/services/ethercat/set-config'
export const ETHERCAT_GET_CONFIG = '/services/ethercat/get-config'
export const ETHERCAT_GET_ESI_LIST = '/services/esi/get_config_list'
export const ETHERCAT_TIMEOUT = 7000

// others
export const NA = 'Unknown'
export const NO_NAME = 'Noname'

export const DEFAULT_TOPOLOGY_FILENAME = 'topology.xml'
export const DEFAULT_TOPOLOGY_DOWNLOAD_FILENAME = 'master.xml'

export const DND_PARAMETER_ROOT = 'dragged/parameter'
