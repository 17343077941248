import { TreeNode } from '@vectioneer/motorcortex-js/src/tree_node'
import FuzzySet from 'fuzzyset'

export type NodeType = {
  children: NodeType[]
  info: Record<string, any> | null
  name: string
  parent: NodeType | null
  type: number
  addChild(node: NodeType): void
  copy(): NodeType
  filter(node: NodeType): NodeType
  flat(): NodeType[]
  getChild(node: NodeType): NodeType
  getInfo(): Record<string, any> | null
  getName(): string
  getParent(): NodeType | null
  getType(): number
  setParent(node: NodeType): void
  setType(type: number): void
}

class SearchMotorcortexTree {
  private constructor(private tree: NodeType, private term: string) {}

  private performSearch() {
    const node = new TreeNode('root') as NodeType

    this.buildTree(node)
    this.mergeDoubleNodes(node)

    return node.children.length > 0 ? [node] : []
  }

  private buildTree(node: NodeType, tree: NodeType = this.tree) {
    tree.children.forEach((el) => {
      const found = this.match(el.name) || this.fuzzySearch(el)

      if (found) {
        let copy = el

        // Copy all parents to build the tree
        while (copy.type > 0) {
          const parent = (copy.getParent() as NodeType).copy()

          if (parent.getParent()?.type === 0) {
            break // break on root
          }

          parent.children = [copy]
          copy = parent
        }

        el = copy
        node.children.push(el)
      } else {
        // TODO: Test this.
        this.buildTree(node, el)
      }
    })
  }

  private mergeDoubleNodes(node: NodeType) {
    const result: NodeType[] = []

    node.children.forEach((el) => {
      const existingIndex = result.findIndex((r: any) => r.name === el.name)

      if (existingIndex < 0) {
        result.push(el)
      } else {
        const children = el.children
        el = result[existingIndex]
        el.children = el.children.concat(children)
      }

      this.mergeDoubleNodes(el)
    })

    node.children = result
  }

  private match(name: string) {
    const pattern = RegExp(this.term, 'i')
    return name.match(pattern)
  }

  private fuzzySearch(el: NodeType) {
    const fuzzySet = FuzzySet()
    const minRequiredScore = 0.75

    const haystack = el.info ? el.info.path : el.name

    fuzzySet.add(haystack)

    const result = fuzzySet.get(this.term)

    let score = 0

    if (result && Array.isArray(result)) {
      ;[score] = result.at(0) as [number, string]
    }

    return score > minRequiredScore
  }

  public static search(tree: any, term: string): NodeType[] {
    return new SearchMotorcortexTree(tree, term).performSearch()
  }
}

export default SearchMotorcortexTree
