import ConfigurationImporter from '/@/shared/importers/Configuration'
import { EthercatDevice } from '/@/shared/entities/EthercatDevice'

class ConfigurationAdapter {
  protected content: string = ''

  public async getData() {
    if (!this.content.length) {
      throw new Error('Content cannot be empty.')
    }

    return ConfigurationImporter.import(this.content)
  }

  public async getDevices() {
    const devices = await this.getData()
    return devices.flatMap((domain) => domain.devices).map((data) => new EthercatDevice(data))
  }
}

export default ConfigurationAdapter
