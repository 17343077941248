import { Component } from 'vue-property-decorator'
import Base from '/@/shared/classes/base'

@Component
export default class Deploy extends Base {
  private app = this.loadAppModule()
  private restart = true
  private configuration = ''

  private get connection() {
    return this.app.connectionParameters
  }

  public async generateConfiguration() {
    try {
      this.configuration = await this.ethercat.generateConfiguration()
    } catch (error) {
      this.$flits.error(error)
      throw error
    }
  }
}
