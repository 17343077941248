import { Action, config, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { EthercatDomain } from '/@/shared/entities/EthercatDomain'
import { EthercatDevice } from '/@/shared/entities/EthercatDevice'

import ConfigurationFormatter from '/@/shared/formatters/Configuration'
import { DEFAULT_TOPOLOGY_DOWNLOAD_FILENAME } from '/@/shared/constants'

import {
  DeviceDir,
  DomainDevice,
  DomainDevices,
  EntryRemove,
  LinkedParameterAdd,
  LinkedParameterRemove,
  MapRemove,
  PDORemove,
  SDORemove,
  EntityUpdates,
  AddDevicesPayload,
  AddDevicePayload,
} from '/@/store/payloads'

import { download, findDescription, readTextFile } from '/@/shared/utils'
import { SDO, Type as SDOTypes } from '/@/shared/entities/SDO'
import { Entry } from '/@/shared/entities/Entry'
import { LinkedParameter } from '/@/shared/entities/LinkedParameter'
import { MapAdd } from '../payloads'
import { Mapping } from '/@/shared/entities/Mapping'
import { ObjectType } from '/@/shared/entities/ObjectType'
import DescriptionImporter from '/@/shared/importers/DeviceDescription'
import { Description, Domain } from '/@/shared/importers/interfaces'
import StartupImporter from '/@/shared/importers/Startup'
import { PDO } from '/@/shared/entities/PDO'
import { DO } from '/@/shared/entities/DO'
import { Dir } from '/@/shared/entities/Dir'
import { CoE, SoE } from '/@/shared/entities/StartupItems'
import { excludeSwitcherFactory } from '/@/shared/entities/ExcludeSwitcher'
import { TypeSignature } from '/@/shared/entities/enums/TypeSignature'

config.rawError = true

const getDomainsBaseState = () => [new EthercatDomain({ isNulldomain: true, name: 'Default' })]
const getNextDeviceIndex = (domains: EthercatDomain[]) => {
  const deviceIDs = domains
    .flatMap((domain) => domain.devices) //
    .map((device) => device.id)

  const lastID = Math.max(...deviceIDs) > -Infinity ? Math.max(...deviceIDs) : -1
  return lastID + 1
}

@Module({ name: 'ethercat', namespaced: true })
class Ethercat extends VuexModule {
  domains: EthercatDomain[] = getDomainsBaseState()

  @Mutation
  public clearState(flush = true) {
    if (flush) {
      this.domains = []
    } else {
      this.domains = getDomainsBaseState()
    }
  }

  @Mutation
  public addDomain(payload?: { data?: any; after?: EthercatDomain }): void {
    const { data = {}, after = undefined } = payload || {}

    if (!after) {
      if (data instanceof EthercatDomain) {
        this.domains.push(data)
      } else {
        this.domains.push(new EthercatDomain(data))
      }
    } else {
      const index = this.domains.indexOf(after)
      this.domains.splice(index + 1, 0, new EthercatDomain(data))
    }
  }

  @Mutation
  public removeDomain(domainId: string): void {
    const domainIndex = this.domains.findIndex((currentDomain) => currentDomain.id === domainId)
    this.domains.splice(domainIndex, 1)

    if (!this.domains.length) {
      this.domains = getDomainsBaseState()
    }
  }

  @Mutation
  public updateEntity({ entity, updates }: EntityUpdates) {
    Object.assign(entity, updates)

    if (entity instanceof EthercatDevice || entity instanceof EthercatDomain) {
      this.domains.forEach((domain) => domain.devices.sort((a, b) => a.id - b.id))
    }
  }

  // device
  @Mutation
  public addDevice({ domain, device, after, autogeneratePosition }: AddDevicePayload) {
    if (!domain) {
      // Add device to default domain.
      domain = (this.domains.find((domain) => domain.isNullDomain) as EthercatDomain) || this.domains[0]
    }

    let position

    if (device && !domain.containsDevice(device)) {
      if (!after) {
        position = domain.devices.push(device) - 1
      } else {
        const index = domain.devices.indexOf(after)
        position = index + 1
        domain.devices.splice(position, 0, device)
      }

      if (autogeneratePosition) {
        device.id = position
      }
    }
  }

  @Mutation
  public setDeviceExcludes(device: EthercatDevice) {
    const excludes = excludeSwitcherFactory(device)

    excludes.forEach((exclude) => {
      exclude.relatedPdos.forEach((pdo) => Object.assign(pdo, { isManagedByExcludeSwitcher: true }))
    })
  }

  @Action
  public addDevices({ domain, devices, after, autogeneratePosition }: AddDevicesPayload) {
    devices.forEach((device) => {
      this.setDeviceExcludes(device)
      this.addDevice({ domain, device, after, autogeneratePosition })
    })
  }

  @Action
  public cloneDomain(domain: EthercatDomain) {
    const clone = new EthercatDomain({ name: `${domain.name} (copy)` })
    this.addDomain({ data: clone })

    domain.devices.forEach((device) => this.cloneDevice({ domain: clone, device, nameAppend: '' }))
  }

  @Mutation
  public cloneDevice({ domain, device, nameAppend }: DomainDevice) {
    const clone = device.clone(nameAppend)
    clone.id = getNextDeviceIndex(this.domains)
    domain.devices.push(clone)
  }

  @Action
  public removeDeviceByUUID(uuid: string) {
    this.domains.forEach((domain) => {
      domain.devices.forEach((device) => {
        if (device.uuid === uuid) {
          this.removeDevice({ domain, device })
        }
      })
    })
  }

  @Mutation
  public removeDevice({ domain, device }: DomainDevice) {
    const deviceIndex = domain.devices.indexOf(device)

    if (deviceIndex > -1) {
      domain.devices.splice(deviceIndex, 1)
    }
  }

  @Mutation
  public clearDevices({ domain }: { domain: EthercatDomain }) {
    domain.devices = []
  }

  @Action
  public removeDevices({ domain, devices = [] }: DomainDevices) {
    devices.forEach((device) => {
      this.context.commit('removeDevice', { domain, device })
    })
  }

  @Mutation
  public toggleVisibility(entity: any) {
    Object.assign(entity, { visible: !entity.visible })
  }

  @Action
  public toggleIncluded(entity: any) {
    this.toggleIncludedValue(entity)

    // Device has excludes that need to be disabled
    if (entity.hasExcludes && entity.included) {
      const device = this.devices.find((device) => device.pdos.includes(entity)) as EthercatDevice
      const excludeSwitcher = excludeSwitcherFactory(device).find((switcher) => switcher.on === entity)

      if (excludeSwitcher) {
        excludeSwitcher.off.forEach((pdo) => this.updateEntity({ entity: pdo, updates: { included: false } }))
      }
    }
  }

  @Mutation
  public toggleIncludedValue(entity: any) {
    Object.assign(entity, { included: !entity.included })

    if (entity instanceof EthercatDomain) {
      entity.devices.forEach((device) => Object.assign(device, { included: entity.included }))
    }
  }

  @Mutation
  public updateSDOType({ sdo, type }: { sdo: SDO; type: SDOTypes }) {
    Object.assign(sdo, {
      type: type.valueOf(),
    })
  }

  @Action
  public updateSDOtypes({ device, dir, type }: { device: EthercatDevice; dir: Dir; type: SDOTypes }) {
    device.sdos
      .filter((sdo) => sdo.dir === dir.valueOf()) //
      .forEach((sdo) => this.updateSDOType({ sdo, type }))
  }

  @Mutation
  public addMapping({ entry }: MapAdd) {
    let offset = 0

    const [lastMapping] = entry.maps.slice(-1)

    if (lastMapping) {
      const [, offsetString] = lastMapping.offset.split(':')

      if (typeof offsetString !== 'undefined') {
        offset = parseInt(offsetString) + 1
      }
    }

    const defaultType = ObjectType.getTypeBySignature(TypeSignature.BIT)

    entry.maps.push(
      new Mapping({
        name: `channel ${offset}`,
        group: '',
        offset: `00:${offset.toString().padStart(2, '0')}`,
        bitLength: defaultType.defaultLength,
        type: defaultType.signature,
      }),
    )
  }

  @Mutation
  public removeMapping({ entry, map }: MapRemove) {
    const mapIndex = entry.maps.indexOf(map)

    if (mapIndex > -1) {
      entry.maps.splice(mapIndex, 1)
    }
  }

  // linked parameter
  @Mutation
  public addLinkedParameter({
    item,
    parameter,
    channel = 0,
    sim = false,
    divide = 1,
    gain = 1,
    gainOffset = 0,
    invert = false,
  }: LinkedParameterAdd) {
    item.linkedParameters.push(new LinkedParameter(parameter, channel, sim, gain, divide, gainOffset, invert))
  }

  @Mutation
  public removeLinkedParameter({ item, parameter }: LinkedParameterRemove) {
    const parameterIndex = item.linkedParameters.indexOf(parameter)
    if (parameterIndex > -1) {
      item.linkedParameters.splice(parameterIndex, 1)
    }
  }

  // pdo
  @Mutation
  public addPDO({ device, dir, after }: DeviceDir): void {
    const pdo = new PDO(dir, {
      fixed: false,
      mandatory: false,
      index: '0000',
      name: '',
      group: '',
      sm: 0,
      excludes: [],
      entries: [],
    })

    if (after) {
      const index = device.pdos.indexOf(after)
      device.pdos.splice(index + 1, 0, pdo)
    } else {
      device.pdos.push(pdo)
    }
  }

  @Mutation
  public removePDO({ device, pdo }: PDORemove): void {
    const pdoIndex = device.pdos.indexOf(pdo)
    if (pdoIndex > -1) {
      device.pdos.splice(pdoIndex, 1)
    }
  }

  // entry
  @Mutation
  public addEntry({ item, after }: { item: DO; after?: Entry }): void {
    const entry = new Entry(
      { bitLength: 0, index: '0000', name: '', group: '', subIndex: 0, type: TypeSignature.NOT_SET, selected: true },
      item.dir,
    )

    if (after) {
      const index = item.entries.indexOf(after)
      item.entries.splice(index + 1, 0, entry)
    } else {
      item.entries.push(entry)
    }
  }

  @Mutation
  public removeEntry({ item, entry }: EntryRemove): void {
    const entryIndex = item.entries.indexOf(entry)

    if (entryIndex > -1) {
      item.entries.splice(entryIndex, 1)
    }
  }

  // sdo
  @Mutation
  public createSDO({ device, sdo }: { device: EthercatDevice; sdo: SDO }) {
    device.sdos.push(sdo)
  }

  @Action
  public addSDO({ device, dir, data = {} }: DeviceDir) {
    const sdo = new SDO(dir, data)
    this.createSDO({ device, sdo })
    this.addEntry({ item: sdo })
  }

  @Mutation
  public removeSDO({ device, sdo }: SDORemove) {
    const sdoIndex = device.sdos.indexOf(sdo)
    if (sdoIndex > -1) {
      device.sdos.splice(sdoIndex, 1)
    }
  }

  @Mutation
  public addStartupItem({ device, item, after }: { device: EthercatDevice; item: CoE | SoE; after?: CoE | SoE }) {
    if (after) {
      const index = device.startups.indexOf(after)
      device.startups.splice(index + 1, 0, item)
    } else {
      device.startups.push(item)
    }
  }

  @Mutation
  public removeStartupItem({ device, item }: { device: EthercatDevice; item: CoE | SoE }) {
    const itemIndex = device.startups.indexOf(item)
    if (itemIndex > -1) {
      device.startups.splice(itemIndex, 1)
    }
  }

  @Mutation
  public applyDeviceDescription({ device, description }: { device: EthercatDevice; description: Description }) {
    device.applyDescription(description)
  }

  @Action
  public async applyDescription(data: { device: EthercatDevice; description: string | File }) {
    if (data.description instanceof File) {
      data.description = await readTextFile(data.description)
    }

    const descriptionData = await DescriptionImporter.import(data.description)
    const device = data.device
    const description = findDescription(device, descriptionData)

    this.applyDeviceDescription({ device, description })
    this.setDeviceExcludes(device)
  }

  @Action
  public generateConfiguration(): string {
    return new ConfigurationFormatter(this.domains).toXML()
  }

  @Action
  public async downloadConfiguration() {
    const configurationString = await this.context.dispatch('generateConfiguration')
    download(DEFAULT_TOPOLOGY_DOWNLOAD_FILENAME, configurationString)
  }

  @Action
  public async applyConfiguration({ domains }: { domains: Domain[] }) {
    this.clearState()

    domains = domains.map((domain) => {
      domain.devices = domain.devices.map((device) => {
        return new EthercatDevice(device)
      })
      return domain
    })

    domains.forEach((domain) => {
      this.addDomain({ data: domain })
      domain.devices.forEach((device) => this.setDeviceExcludes(device as EthercatDevice))
    })
  }

  @Action
  public async addStartup(data: { device: EthercatDevice; startup: string | File }) {
    if (data.startup instanceof File) {
      data.startup = await readTextFile(data.startup)
    }

    const startups = await StartupImporter.import(data.startup)
    this.updateEntity({ entity: data.device, updates: { startups: startups } })
  }

  public get isDeviceNameTaken() {
    return (name: string) => {
      return this.domainDevices.filter((device) => device.name === name).length > 1
    }
  }

  public get isPositionTaken() {
    return (position: number) => {
      return this.domainDevices.filter((device) => device.id === position).length > 1
    }
  }

  public get domainDevices() {
    return this.domains
      .map((domain) => domain.devices)
      .flat(1)
      .filter((device) => device.included)
  }

  public get findDevice() {
    return (uuid: string) => {
      return this.devices.find((device) => device.uuid === uuid)
    }
  }

  public get hasDevices() {
    return this.devices.length > 0
  }

  public get devices() {
    return this.domains.flatMap((domain) => domain.devices)
  }

  public get nextDeviceIndex() {
    return getNextDeviceIndex(this.domains)
  }

  public get hasDomains() {
    return this.domains.length > 0
  }

  public get findDomain() {
    return (name: string) => this.domains.find((domain) => domain.name === name)
  }

  public get findDomainById() {
    return (id: string) => this.domains.find((domain) => domain.id === id)
  }

  public get deviceExcludeList() {
    return (device: EthercatDevice) => excludeSwitcherFactory(device)
  }
}

export default Ethercat
