import DescriptionImporter from '/@/shared/importers/DeviceDescription'
import { EthercatDevice } from '/@/shared/entities/EthercatDevice'

class Adapter {
  protected content: string = ''
  protected importer: any = null
  protected index = 0

  public setContent(content: string) {
    this.content = content
    return this
  }

  public async getDevices() {
    if (!this.content.length) {
      throw new Error('Content cannot be empty.')
    }

    const importer = (await DescriptionImporter.import(this.content)).map((description) => {
      delete description.hideTypes

      const result = { ...description, id: this.index }
      this.index++
      return result
    })

    return importer.map((data) => new EthercatDevice(data))
  }
}

export default Adapter
