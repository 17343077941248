import { parseStringPromise } from 'xml2js'
import { firstCharLowerCase, parseBooleans, parseNumbers } from 'xml2js/lib/processors'
import { Domain, Topology as TopologyInterface } from './interfaces'
import {
  extractType,
  extractSyncManagers,
  extractPDOs,
  extractSDOs,
  extractName,
  extractDC,
  extractIncluded,
  extractLinks,
} from './extractors'
import StartupImporter from './Startup'
import { parseIndex } from './processors'

class Configuration {
  static files: any = []

  static async import(xmlData: string, files: any[] = []) {
    const result = await parseStringPromise(xmlData, {
      tagNameProcessors: [firstCharLowerCase],
      attrNameProcessors: [firstCharLowerCase],
      valueProcessors: [parseBooleans, parseIndex],
      attrValueProcessors: [parseBooleans],
      trim: true,
      explicitArray: false,
    })

    if (files === null) {
      files = []
    }

    this.files = files.map(async (file: any) => {
      file.data = await StartupImporter.import(file.data)
      return file
    })

    this.files = await Promise.all(this.files)
    return this.formatConfiguration(result)
  }

  static formatConfiguration(result: any): Domain[] {
    let domainData = result.motorcortex.fieldBusList.fieldBus.domain

    if (!Array.isArray(domainData)) {
      domainData = [domainData]
    }

    return domainData.map((domain: any) => {
      const devices = this.extractDevices(domain.device)

      return {
        name: domain.$.name || '',
        simulation: domain.$.simulation || false,
        devices,
        ...(domain.$.frequencyDivider > 1 ? { frequencyDivider: domain.$.frequencyDivider } : {}),
        ...extractIncluded(domain),
      }
    })
  }

  static extractDevices(devices: any[]): TopologyInterface[] {
    if (!Array.isArray(devices)) {
      devices = [devices]
    }

    return devices
      .filter((device) => device !== undefined)
      .map((device, id) => {
        const type = extractType(device.type)
        const syncManagers = extractSyncManagers(device.sm)

        const linkedParameters = extractLinks(device.linkSimulation, 'input')
        const rxPdos = extractPDOs(device.rxPdo, 'input')
        const txPdos = extractPDOs(device.txPdo, 'output')
        const rxSdos = extractSDOs(device.rxSdo, 'input')
        const txSdos = extractSDOs(device.txSdo, 'output')

        const dc = extractDC(device.dc)
        const mailbox = this.extractMailbox(device.mailbox)

        return {
          id: device.$.alias || 0,
          vendorId: type?.vendorId,
          name: extractName(device),
          allowOverlappingPdos: device.$.allowOverlappingPdos || false,
          type,
          syncManagers,
          rxPdos,
          txPdos,
          rxSdos,
          txSdos,
          ...(linkedParameters !== null ? { linkedParameters } : null),
          ...(dc !== null ? { dc } : null),
          ...(mailbox !== null ? { mailbox } : null),
          ...extractIncluded(device),
        }
      })
  }

  static extractMailbox(mailbox: any) {
    if (!mailbox) {
      return null
    }

    const filename = mailbox?.$?.file
    let file

    if (filename) {
      file = this.files.find((file: any) => file.name === filename)

      if (!file) {
        return null
      }

      return file.data
    }

    return StartupImporter.formatStartups(mailbox)
  }
}

export default Configuration
