import { Dir } from '/@/shared/entities/Dir'
import { Entry } from '/@/shared/entities/Entry'
import { NA } from '/@/shared/constants'
import { Type } from 'class-transformer'
import { Pdo as PdoDataInterface } from '../importers/interfaces'
import Hideable from './shared/Hideable'

export class DO extends Hideable {
  public name: string = NA
  public group: string = ''

  public readonly dir: Dir = Dir.XX

  @Type(() => Entry)
  public readonly entries: Entry[] = []

  constructor(dir: Dir, data: PdoDataInterface) {
    super(data)
    this.name = data.name
    this.group = data.group
    this.dir = dir

    if (data.entries) {
      this.entries = data.entries.map((data) => new Entry(data, this.dir))
    }
  }
}
