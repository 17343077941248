import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import trans from '/@/i18n'

interface InterfaceEthercatStatus {
  code: number
  text: string
}

interface InterfaceEthercatResponse {
  status: InterfaceEthercatStatus
  files: { name: string; data: string }[]
  data: string
}

const bindInterceptors = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    (config) => {
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  instance.interceptors.response.use(
    (response: AxiosResponse<InterfaceEthercatResponse>) => {
      if (response.data.status?.code > 0) {
        throw new Error(response.data.status.text)
      }

      // TODO: implement axios response interceptor

      return response
    },
    (error) => {
      if (error.response) {
        return Promise.reject(error)
      }

      if (!error.status) {
        return Promise.reject(trans.t('Connection failed, check your connection settings.'))
      }

      return Promise.reject(error)
    },
  )
}

export const createHttpInstance = (baseURL: string = '/', timeout: number = 5000, config: AxiosRequestConfig = {}) => {
  const instance = axios.create({
    baseURL,
    timeout,
    ...config,
  })

  bindInterceptors(instance)
  return instance
}

export const http = createHttpInstance()
