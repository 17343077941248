import { NA } from '/@/shared/constants'
import { SyncManager } from '/@/shared/entities/SyncManager'
import { DistributedClock } from '/@/shared/entities/DistributedClock'
import { PDO } from '/@/shared/entities/PDO'
import { Dir } from '/@/shared/entities/Dir'
import { DeviceType } from '/@/shared/entities/DeviceType'
import { Type } from 'class-transformer'
import { SDO } from '/@/shared/entities/SDO'
import { areTheSameHexValue, haveTheSameIndexAndSubIndex, uuid } from '/@/shared/utils'
import { Description, Entry as EntryData, Topology } from '../importers/interfaces'
import { CoE, SoE } from '/@/shared/entities/StartupItems'
import Excludable from './shared/Excludable'
import { Entry } from '/@/shared/entities/Entry'
import { findEntry } from '../utils'
import { LinkedParameter } from './LinkedParameter'
import HexId from '../values/HexId'

export class EthercatDevice extends Excludable {
  public readonly uuid = uuid()
  public id: number = -1
  public name: string = NA
  public selected: boolean = true
  public vendorId: string
  public allowOverlappingPdos: boolean = false

  @Type(() => DeviceType)
  public type: DeviceType = new DeviceType()

  @Type(() => SyncManager)
  public syncManagers: SyncManager[] = []

  @Type(() => DistributedClock)
  public distributedClock: DistributedClock = new DistributedClock()

  @Type(() => PDO)
  public pdos: PDO[] = []

  @Type(() => SDO)
  public sdos: SDO[] = []

  @Type(() => LinkedParameter)
  public readonly linkedParameters: LinkedParameter[] = []

  public startups: (SoE | CoE)[] = []

  // TODO Add interfaces
  constructor(data: Topology = { id: -1, vendorId: 0, name: NA, type: new DeviceType(), allowOverlappingPdos: false }) {
    super(data)

    this.id = data.id
    this.vendorId = String(HexId.make(data.vendorId))
    this.name = data.name
    this.allowOverlappingPdos = data.allowOverlappingPdos || false

    if (data.type) {
      this.type = new DeviceType(data.type)
    }

    if (data.syncManagers) {
      this.syncManagers = data.syncManagers.map((data) => new SyncManager(data))
    }

    if (data.rxPdos) {
      data.rxPdos.forEach((data) => this.pdos.push(new PDO(Dir.RX, data)))
    }

    if (data.txPdos) {
      data.txPdos.forEach((data) => this.pdos.push(new PDO(Dir.TX, data)))
    }

    if (data.rxSdos) {
      data.rxSdos.forEach((data) => this.sdos.push(new SDO(Dir.RX, data)))
    }

    if (data.txSdos) {
      data.txSdos.forEach((data) => this.sdos.push(new SDO(Dir.TX, data)))
    }

    if (data.dc) {
      this.distributedClock = new DistributedClock(data.dc)
    }

    if (data.mailbox) {
      this.startups = data.mailbox
    }

    if (data.linkedParameters) {
      this.linkedParameters = data.linkedParameters.map((data) => LinkedParameter.fromData(data))
    }
  }

  public clone(nameAppend: string = ' (copy)') {
    const clone = new EthercatDevice()

    // TODO: Test this with structuredClone
    clone.id = this.id
    clone.vendorId = this.vendorId
    clone.name = this.name + nameAppend
    clone.syncManagers = this.syncManagers.map((data) => new SyncManager(data))
    clone.type = new DeviceType(this.type)
    clone.startups = this.startups.map((startup) => startup.clone())
    clone.distributedClock = new DistributedClock(this.distributedClock)

    this.pdos.forEach((pdo) => clone.pdos.push(new PDO(pdo.dir, pdo as any)))
    this.sdos.forEach((sdo) => clone.sdos.push(new SDO(sdo.dir, sdo as any)))

    return clone
  }

  public applyDescription(data: Description) {
    if (data.type) {
      this.type = new DeviceType(data.type)
    }

    if (!this.name && data.name) {
      this.name = data.name
    }

    const rxPdos = data?.rxPdos || []
    const txPdos = data?.txPdos || []

    const pdos = rxPdos
      .map((pdo) => {
        return {
          ...pdo,
          dir: Dir.RX,
        }
      })
      .concat(
        txPdos.map((pdo) => {
          return {
            ...pdo,
            dir: Dir.TX,
          }
        }),
      )

    pdos.forEach((pdoData) => {
      const target = this.pdos.find((pdo) => {
        return pdo.dir === pdoData.dir && areTheSameHexValue(pdo.index, pdoData.index)
      })

      if (target) {
        const existingEntries = target.entries.map((entry, index) => {
          /**
           * First i check if the entry at the same array index exists
           * Next i check if they have the same index and subindex
           * if they don't i use a lookup to search for the entry in the
           * parsed data.
           *
           * This might fail because gaps can share the same index/subindex
           * and can have different bitlengths.
           */

          let entryData = pdoData.entries.at(index)

          if (!haveTheSameIndexAndSubIndex(entry, entryData)) {
            entryData = (pdoData.entries ?? []).find((t, index) => findEntry(entry, t))
          }

          if (entryData) {
            return entry.merge(entryData)
          }

          return entry
        })

        const pdoFilterFunction = (entry: EntryData) => {
          return existingEntries.find((t) => findEntry(t, entry)) === undefined
        }

        const newEntries = (pdoData.entries ?? []) //
          .filter(pdoFilterFunction)
          .map((entry) => new Entry(entry, target.dir))

        Object.assign(target, { ...pdoData, entries: existingEntries.concat(newEntries) })
      } else {
        this.pdos.push(new PDO(pdoData.dir, pdoData))
      }
    })

    this.pdos.sort((a, b) => {
      return parseInt(a.index, 16) - parseInt(b.index, 16)
    })
  }
}
