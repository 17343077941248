import motorcortex from '@vectioneer/motorcortex-js'

import { TIMEOUT_MS, QUEUE_LENGTH } from '/@/shared/constants'
import { ConnectionParameters } from './ConnectionParameters'
import { SessionState, stateEvents, sessionStatus } from './entities/enums/SessionState'

const motorcortexTypes = new motorcortex.MessageTypes()
const request = new motorcortex.RequestAsync(motorcortexTypes)
const subscribe = new motorcortex.SubscribeAsync(request)

export type MotorcortexConfig = {
  on?: Partial<Record<sessionStatus, EventListener>>
}

export default class Motorcortex extends EventTarget {
  private connectionParameters!: ConnectionParameters
  private session: any = null
  private connection: any = null

  public constructor(parameters: ConnectionParameters, config: MotorcortexConfig = {}) {
    super()
    this.connectionParameters = parameters

    if (config.hasOwnProperty('on') && config.on) {
      Object.entries(config.on).forEach(([event, listener]) => {
        this.on(event as sessionStatus, listener)
      })
    }
  }

  private get canConnect() {
    return this.connectionParameters?.canConnect ?? false
  }

  private get sessionParameters() {
    return [
      {
        host: this.connectionParameters.host,
        request_port: this.connectionParameters.requestPort,
        subscribe_port: this.connectionParameters.receivePort,
        security: this.connectionParameters.secured,
        request_timeout_ms: TIMEOUT_MS,
        tree_timeout_ms: TIMEOUT_MS,
        queue_length: QUEUE_LENGTH,
      },
      {
        login: this.connectionParameters.login,
        password: this.connectionParameters.password,
      },
    ]
  }

  public async updateConnectionParameters(parameters: ConnectionParameters) {
    try {
      await this.disconnect()
      this.connectionParameters = parameters
      await this.connect()
    } catch (error) {
      throw error
    }
  }

  public getTree() {
    if (!this.connection) {
      throw new Error('Not connected')
    }

    return Object.freeze(this.connection.getTree())
  }

  public async connect() {
    if (!this.canConnect) {
      throw new Error('Cannot connect to Motorcortex, please check your connection settings')
    }

    try {
      this.session = new motorcortex.SessionManager(request, subscribe)
      this.connection = await this.session.open(...this.sessionParameters)

      this.startListening()
      return { session: this.session, connection: this.connection }
    } catch (error) {
      throw error
    }
  }

  public async disconnect() {
    if (!this.session) {
      return
    }

    try {
      await this.session.close()
    } catch (error) {
      throw error
    }
  }

  public on(event: sessionStatus | sessionStatus[], listener: EventListener) {
    if (Array.isArray(event)) {
      event.forEach((event) => this.addEventListener(event, listener))
      return
    }

    this.addEventListener(event, listener)
  }

  public off(event: sessionStatus, listener: EventListener) {
    this.removeEventListener(event, listener)
  }

  private dispatchEventForState(state: SessionState) {
    const eventName = stateEvents[state]

    if (!eventName) {
      throw new Error(`Unknown session state: ${state}`)
    }

    this.dispatchEvent(new Event(eventName))
  }

  private startListening() {
    this.session.notify((message: { state: number; tree_manager: any }) => {
      this.dispatchEventForState(message.state)
    })
  }
}
