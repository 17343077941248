import { DC } from '../importers/interfaces'
import Excludable from './shared/Excludable'
/**
 * Distributed clock
 */
export class DistributedClock extends Excludable {
  public assignActivate = 0
  public cycleTS0 = 0
  public cycleTS1 = 0
  public shiftTS0 = 0
  public shiftTS1 = 0
  public included = false

  constructor(data: DC | null = null) {
    super({})
    this.included = data?.included ?? false

    if (data) {
      this.assignActivate = data.assignActivate
      this.cycleTS0 = data.cycleTS0
      this.cycleTS1 = data.cycleTS1
      this.shiftTS0 = data.shiftTS0
      this.shiftTS1 = data.shiftTS1
    }
  }
}
