import { Component } from 'vue-property-decorator'
import Base from '/@/shared/classes/base'
import EthercatSettings from '/@/shared/EthercatSettings'

@Component
export default class Settings extends Base {
  private app = this.loadAppModule()

  private get ethercatSettings() {
    const handler = {
      set: (ethercatSettings: EthercatSettings, prop: string, value: any) => {
        this.app.updateEthercatSettingsProperty({ [prop]: value })
        return true
      },
    }

    return new Proxy(this.app.ethercatSettings, handler)
  }

  private updateEndpoints(value: string, key: string) {
    type endpointKeys = keyof typeof this.ethercatSettings.endpoints
    const endpointKey = key as endpointKeys

    const endpoints = Object.assign({}, this.ethercatSettings.endpoints)
    endpoints[endpointKey] = value

    this.ethercatSettings.endpoints = endpoints
  }

  public changeCamelCaseToSpaces(value: string) {
    return value
      .replace(/([A-Z])/g, ' $1')
      .trim()
      .toLocaleLowerCase()
  }

  public reset() {
    this.ethercatSettings.reset()
  }
}
