import { TypeSignature } from '../enums/TypeSignature'
import Hideable from './Hideable'
import { ObjectType } from '/@/shared/entities/ObjectType'

export default class Typeable extends Hideable {
  // Defined by sub-class
  public declare type: TypeSignature
  public declare bitLength: number

  public constructor(...args: any[]) {
    super(args)
  }

  public get objectType() {
    return ObjectType.getTypeBySignature(this.type)
  }

  public set objectType(type: ObjectType) {
    this.type = type.signature
  }

  public getTypeString(): string {
    const signature = this.objectType

    if (signature.changeLengthAllowed) {
      return TypeSignature[this.type] + this.bitLength
    }

    return TypeSignature[this.type]
  }

  public getBitLength() {
    const signature = this.objectType

    if (signature.indexMultiplier > 1) {
      return this.bitLength * signature.indexMultiplier
    }

    return this.bitLength
  }

  public determineNewBitLengthFor(type: ObjectType): number {
    const options = [TypeSignature.INT, TypeSignature.UINT]
    const shouldRememberPreviousValue = options.includes(this.objectType.signature) && options.includes(type.signature)

    if (shouldRememberPreviousValue) {
      return this.bitLength
    }

    return type.defaultLength
  }
}
