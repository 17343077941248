import ConfigurationImporter from '/@/shared/importers/Configuration'

class Adapter {
  protected content: string = ''
  protected files: { name: string; data: string }[] = []
  protected importer: any = null
  protected index = 0

  public setContent(content: string) {
    this.content = content
    return this
  }

  public async getDomains() {
    if (!this.content.length) {
      throw new Error('Content cannot be empty')
    }

    return await ConfigurationImporter.import(this.content, this.files)
  }
}

export default Adapter
