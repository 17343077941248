import { Component, Vue } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import EthercatModule from '/@/store/modules/ethercat'
import AppModule from '/@/store/modules/app'

@Component
export default class Base extends Vue {
  public ethercat = getModule(EthercatModule, this.$store)

  protected get domains() {
    return this.ethercat.domains
  }

  protected get hasDomains() {
    return this.ethercat.hasDomains
  }

  public loadAppModule() {
    return getModule(AppModule, this.$store)
  }

  public redirectToRoot() {
    if (this.$route.path !== '/') {
      this.$router.push('/')
    }
  }
}
