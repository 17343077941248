import { Component } from 'vue-property-decorator'
import Base from '/@/shared/classes/base'
import { ConnectionParameters } from '../../shared/ConnectionParameters'

@Component
export default class Connection extends Base {
  private app = this.loadAppModule()

  private get connection() {
    const handler = {
      set: (connection: ConnectionParameters, prop: string, value: any) => {
        this.app.updateConnectionProperty({ [prop]: value })
        return true
      },
    }

    return new Proxy(this.app.connectionParameters, handler)
  }
}
