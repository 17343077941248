import 'reflect-metadata'

import Vue from 'vue'
import App from './app/index.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import Logger from '/@/shared/error-logger'

import '/@/shared/plugins'
import '/@/shared/components'
import '/@/shared/modals'
import '/@/shared/directives'
import '/@css/style.css'
import { provideDialog, provideFlits, provideModal } from '@vectioneer/ui'

Vue.config.productionTip = false

class Application {
  constructor() {
    this.run()
  }

  public run() {
    new Vue({
      router,
      store,
      i18n,
      render: (h) => h(App),
      setup() {
        provideModal()
        provideFlits()
        provideDialog()
      },
    }).$mount('#app')

    this.initLogger()
  }

  private initLogger() {
    const logger = new Logger()

    Vue.config.errorHandler = (err) => {
      logger.addErrorRecord(err)

      if (import.meta.env.VITE_DEBUG) {
        throw err
      }
    }
  }
}

export default new Application()
