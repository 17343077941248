import { create } from 'xmlbuilder2'
import { EthercatDomain } from '/@/shared/entities/EthercatDomain'
import { EthercatDevice } from '/@/shared/entities/EthercatDevice'
import { PDO } from '/@/shared/entities/PDO'
import { Dir } from '/@/shared/entities/Dir'
import { CoE, SoE, Transition } from '/@/shared/entities/StartupItems'
import { SDO } from '/@/shared/entities/SDO'
import { extractIfExists } from '../utils'
import { formatIndex, createLinkElements, createEntryElements, addVisiblePoperty, addIncludedProperty } from './helpers'
import { XMLBuilder } from 'xmlbuilder2/lib/interfaces'

class Configuration {
  private root: XMLBuilder
  private name = 'Motorcortex'
  private attributes: { [key: string]: any } = {
    Version: '2.0',
  }
  private readonly pretty: boolean

  constructor(data: EthercatDomain[], pretty: boolean = true) {
    this.pretty = pretty
    this.root = create().ele(this.name)

    this.setRootAttributes()
    const fieldbus = this.addFieldbus()

    data.forEach((domain) => {
      const element = fieldbus.ele('Domain', {
        Group: '',
        Name: domain.name,
        Simulation: domain.isSimulated,
        ...(domain.frequencyDivider > 1 ? { FrequencyDivider: Math.trunc(domain.frequencyDivider) } : {}),
        ...addIncludedProperty(domain),
      })

      this.addDevices(domain, element)
    })
  }

  private addDevices(domain: EthercatDomain, domainEl: XMLBuilder) {
    domain.devices.forEach((device) => {
      const deviceEl = domainEl.ele('Device', {
        Alias: device.id,
        Name: device.name,
        AllowOverlappingPdos: device.allowOverlappingPdos,
        ...addIncludedProperty(device),
      })

      this.configureDevice(device, deviceEl)
    })
  }

  private configureDevice(device: EthercatDevice, deviceEl: XMLBuilder) {
    this.configureMailbox(device, deviceEl.ele('Mailbox'))

    createLinkElements(deviceEl, device, 'LinkSimulation')

    this.configureDC(
      device,
      deviceEl.ele('Dc', {
        ...addIncludedProperty(device.distributedClock),
      }),
    )

    deviceEl
      .ele('Type', {
        VendorId: device.vendorId,
        ProductCode: device.type.productCode,
        RevisionNo: device.type.revisionNo,
      })
      .txt(device.type.name)

    device.syncManagers.forEach((syncManager) => {
      deviceEl
        .ele('Sm', {
          Enable: syncManager.enable,
          StartAddress: syncManager.startAddress,
          ControlByte: syncManager.controlByte,
          DefaultSize: syncManager.defaultSize,
        })
        .txt(syncManager?.name)
    })

    this.addPDOs(device.pdos, deviceEl)
    this.addSDOs(device.sdos, deviceEl)
  }

  private configureDC(device: EthercatDevice, dcEl: XMLBuilder) {
    dcEl.ele('AssignActivate').txt(device.distributedClock.assignActivate.toString())
    dcEl.ele('CycleTimeSync0').txt(device.distributedClock.cycleTS0.toString())
    dcEl.ele('CycleTimeSync1').txt(device.distributedClock.cycleTS1.toString())
    dcEl.ele('ShiftTimeSync0').txt(device.distributedClock.shiftTS0.toString())
    dcEl.ele('ShiftTimeSync1').txt(device.distributedClock.shiftTS1.toString())
  }

  private configureMailbox(device: EthercatDevice, mailboxEl: XMLBuilder) {
    if (device.startups.length > 0) {
      let scoe: XMLBuilder
      if (device.startups[0] instanceof SoE) {
        scoe = mailboxEl.ele('SoE')
      } else {
        scoe = mailboxEl.ele('CoE')
      }

      const commands = scoe.ele('InitCmds')

      device.startups.forEach((startup: SoE | CoE) => {
        const command = commands.ele('InitCmd', {
          ...addIncludedProperty(startup),
        })

        command.ele('Transition').txt(Transition[startup.transition])
        command.ele('Comment').txt(startup.comment)
        command.ele('Timeout').txt(startup.timeout?.toString())

        if (startup instanceof SoE) {
          command.ele('OpCode').txt(startup.opCode.toString())
          command.ele('DriveNo').txt(startup.driveNo.toString())
          command.ele('IDN').txt(startup.idn.toString())
          command.ele('Elements').txt(startup.elements.toString())
          command.ele('Attribute').txt(startup.attribute.toString())
        } else {
          command.ele('Ccs').txt(startup.ccs?.toString())
          command.ele('Index').txt(formatIndex(startup.index))
          command.ele('SubIndex').txt(startup.subIndex.toString())

          if (startup.completeAccess) {
            command.att('CompleteAccess', startup.completeAccess.toString())
          }
        }

        command
          .ele('Data', {
            DataType: startup.data.datatype,
            BitLen: startup.data.bitLength.toString(),
          })
          .txt(startup.data.formattedValue.toString())
      })
    }
  }

  private addSDOs(sdos: SDO[], deviceEl: XMLBuilder) {
    const rxSdos = sdos.filter((sdo) => sdo.dir === Dir.RX)
    const txSdos = sdos.filter((sdo) => sdo.dir === Dir.TX)

    this.createSDOs('RxSdo', deviceEl, rxSdos)
    this.createSDOs('TxSdo', deviceEl, txSdos)
  }

  private createSDOs(name: string, deviceEl: XMLBuilder, sdos: SDO[]) {
    const createEl = (name: string, sdo: SDO) => {
      return deviceEl.ele(name, {
        ...extractIfExists(sdo, 'name'),
        ...extractIfExists(sdo, 'group'),
        ...addIncludedProperty(sdo),
      })
    }

    sdos.forEach((sdo) => {
      const sdoEl = createEl(name, sdo)
      sdoEl.ele('Type').txt(sdo.type)
      createEntryElements(sdoEl, sdo)
    })
  }

  private addPDOs(pdos: PDO[], deviceEl: XMLBuilder) {
    const rxPdos = pdos.filter((pdo) => pdo.dir === Dir.RX)
    const txPdos = pdos.filter((pdo) => pdo.dir === Dir.TX)

    this.createPDOs('RxPdo', deviceEl, rxPdos)
    this.createPDOs('TxPdo', deviceEl, txPdos)
  }

  private createPDOs(name: string, deviceEl: XMLBuilder, pdos: PDO[]) {
    const createEl = (name: string, pdo: PDO) => {
      return deviceEl.ele(name, {
        Sm: pdo.sm,
        Fixed: pdo.fixed,
        Mandatory: pdo.mandatory,
        ...extractIfExists(pdo, 'name'),
        ...extractIfExists(pdo, 'group'),
        ...addVisiblePoperty(pdo),
        ...addIncludedProperty(pdo),
      })
    }

    pdos.forEach((pdo) => {
      const pdoEl = createEl(name, pdo)
      pdoEl.ele('Index').txt(formatIndex(pdo.index))

      createLinkElements(pdoEl, pdo)
      createEntryElements(pdoEl, pdo)
    })
  }

  private addFieldbus() {
    return this.root.ele('FieldBusList').ele('FieldBus', {
      Type: 'EtherCAT',
      Device: 0,
      Name: 'Ethercat',
    })
  }

  private setRootAttributes() {
    Object.keys(this.attributes).forEach((key) => this.root.att(key, this.attributes[key]))
  }

  public toXML() {
    return this.root.end({ prettyPrint: this.pretty })
  }
}

export default Configuration
