export enum SessionState {
  CONNECTING,
  CONNECTION_OK,
  CONNECTION_LOST,
  CONNECTION_FAILED,
  DISCONNECTING,
  DISCONNECTED,
}

export const stateEvents = {
  [SessionState.CONNECTING]: 'connecting',
  [SessionState.CONNECTION_OK]: 'connected',
  [SessionState.CONNECTION_LOST]: 'connectionLost',
  [SessionState.CONNECTION_FAILED]: 'connectionFailed',
  [SessionState.DISCONNECTING]: 'disconnecting',
  [SessionState.DISCONNECTED]: 'disconnected',
} as const

export type sessionStatus = typeof stateEvents[SessionState]
