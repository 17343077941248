import { Buffer } from 'buffer'

export const floatToUint32 = (value: number) => {
  const f32 = new Float32Array(1)
  const u32 = new Uint32Array(f32.buffer)
  f32[0] = parseFloat(value.toString())
  return u32[0]
}

export const uint32ToFloat = (value: number) => {
  const u32 = new Uint32Array(1)
  const f32 = new Float32Array(u32.buffer)
  u32[0] = value
  return f32[0]
}

/**
 * @description - Convert a hex value to a buffer of the specified bit length
 * @param value - little endian hex value
 * @param bitLength - bit length of the value
 * @returns Buffer
 */
export const createSizedBufferFrom = (value: string, bitLength: number): Buffer => {
  const valueBuffer = Buffer.from(value, 'hex')
  const bytes = bitLength / 8

  const sizedBuffer = Buffer.alloc(bytes, 0)
  sizedBuffer.set(valueBuffer.subarray(0, bytes), 0)

  return sizedBuffer
}

export const padZeros = (value: string, bitlength: number, littleEndian = true) => {
  if (!littleEndian) {
    return value.padStart(bitlength / 4, '0')
  }

  return value.padEnd(bitlength / 4, '0')
}

export const addLeadingZero = (value: string) => {
  return value.padStart(value.length % 2 ? value.length + 1 : value.length, '0')
}

export const intToHex = (value: number, bitLength: number = 32) => {
  const hexValue = value.toString(16)
  const paddedValue = padZeros(hexValue, bitLength, false)
  return '#x' + paddedValue.slice(-bitLength / 4)
}

export const hexToInt = (value: string) => {
  if (value.startsWith('#x')) {
    value = value.slice(2)
  }

  const intValue = parseInt(value, 16)

  if (isNaN(intValue)) {
    throw new Error(`Invalid hex value: ${value}`)
  }

  return intValue
}
