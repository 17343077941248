import { Dir } from '/@/shared/entities/Dir'
import { Type } from 'class-transformer'
import { Pdo as PdoDataInterface } from '../importers/interfaces'
import { LinkedParameter } from '/@/shared/entities/LinkedParameter'
import { DO } from './DO'

export class PDO extends DO {
  public readonly index: string = '-1'
  public readonly fixed: boolean = false
  public readonly mandatory: boolean = false
  public readonly sm: number = 0
  public readonly excludes: string[] = []

  public isManagedByExcludeSwitcher = false

  @Type(() => LinkedParameter)
  public readonly linkedParameters: LinkedParameter[] = []

  public get hasExcludes() {
    return this.excludes.length > 0
  }

  constructor(dir: Dir, data: PdoDataInterface) {
    super(dir, data)

    this.index = data.index
    this.fixed = data.fixed
    this.mandatory = data.mandatory
    this.sm = data.sm ?? Dir.getDefaultSyncManager(dir)
    this.excludes = data.excludes

    if (this.excludes.length) {
      this.included = false
    }

    if (data.linkedParameters) {
      this.linkedParameters = data.linkedParameters.map((data) => LinkedParameter.fromData(data))
    }
  }
}
