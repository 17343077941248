export enum Dir {
  XX,
  RX,
  TX,
}

export namespace Dir {
  export function getDefaultSyncManager(dir: Dir) {
    const value = Dir[dir]

    if (value === 'XX') {
      return 0
    }

    return value === 'RX' ? 2 : 3
  }

  export function list() {
    return [
      {
        name: 'rx',
        value: Dir.RX,
      },
      {
        name: 'tx',
        value: Dir.TX,
      },
    ]
  }
}
