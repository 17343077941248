import store from '/@/store'
import EthercatModule from '/@/store/modules/ethercat'
import AppModule from '/@/store/modules/app'
import { getModule } from 'vuex-module-decorators'

class Base {
  protected get ethercat() {
    return getModule(EthercatModule, store)
  }

  protected get app() {
    return getModule(AppModule, store)
  }
}

export default Base
