import { Dir } from '/@/shared/entities/Dir'
import { DO } from './DO'

export enum Type {
  COE = 'COE',
  SOE = 'SOE',
}

export class SDO extends DO {
  public type: Type = Type.COE

  constructor(dir: Dir, data: any) {
    super(dir, data)
    this.type = data.type
  }
}
