import { Data } from './startup-items/Data'
import Excludable from './shared/Excludable'
import { cloneClass } from '../utils'

export enum Transition {
  XX,
  IP,
  PS,
  SO,
}

export function getTransition(type: string | number): Transition {
  if (typeof type === 'number') {
    return type
  }

  if (type === 'PS') {
    return Transition.PS
  }
  if (type === 'IP') {
    return Transition.IP
  }
  if (type === 'SO') {
    return Transition.SO
  }
  return Transition.XX
}

export namespace Transition {
  export function list() {
    return [
      {
        name: 'ip',
        value: Transition.IP,
      },
      {
        name: 'ps',
        value: Transition.PS,
      },
      {
        name: 'so',
        value: Transition.SO,
      },
    ]
  }
}

export interface IBaseStartupData {
  transition: string
  comment: string
  timeout: number
  data: string
  dataObject: Data
}

export interface ICoEData extends IBaseStartupData {
  ccs: number
  index: string
  subIndex: number
  completeAccess: boolean
}

export interface ISoEData extends IBaseStartupData {
  opCode: number
  driveNo: number
  idn: number
  elements: number
  attribute: number
}

export class BaseStartupItem extends Excludable {
  public data: Data
  public transition: Transition = Transition.PS
  public comment: string = ''
  public timeout: number = 0

  constructor(startupData?: IBaseStartupData) {
    super(startupData ?? {})

    if (startupData) {
      this.transition = getTransition(startupData.transition)
      this.comment = startupData.comment
      this.timeout = startupData.timeout
      this.data = startupData.dataObject
    } else {
      this.data = new Data('')
    }
  }

  public clone() {
    const clone = cloneClass(this)
    clone.data = cloneClass(this.data)
    return clone
  }
}

export class CoE extends BaseStartupItem {
  public ccs: number = 0
  public index: string = '0000'
  public subIndex: number = 0
  public completeAccess: boolean = false

  constructor(startupData?: ICoEData) {
    super(startupData)

    if (startupData) {
      this.ccs = startupData.ccs
      this.index = startupData.index
      this.subIndex = startupData.subIndex
      this.completeAccess = startupData.completeAccess
    }
  }
}

export class SoE extends BaseStartupItem {
  public opCode: number = 3
  public driveNo: number = 0
  public idn: number = 0
  public elements: number = 0
  public attribute: number = 0

  constructor(startupData?: ISoEData) {
    super(startupData)

    if (startupData) {
      this.opCode = startupData.opCode
      this.driveNo = startupData.driveNo
      this.idn = startupData.idn
      this.elements = startupData.elements
      this.attribute = startupData.attribute
    }
  }
}
