<template>
  <v-panel class="parameter-create">
    <form @submit.prevent="create" @keyup.enter="create">
      <fieldset>
        <div class="inline channel">
          <v-input icon="git-merge" v-model="state.path" :placeholder="$t('path/property')">
            {{ $t('Path') }}
          </v-input>

          <v-input icon="sliders" v-model.number="state.channel" type="number">
            {{ $t('Channel') }}
          </v-input>
        </div>

        <v-switch icon="plus" label-on="on" label-off="off" v-model="state.sim">{{ $t('Simulation') }}</v-switch>

        <div class="inline">
          <v-input icon="trending-up" v-model.number="state.gain" type="number">
            {{ $t('Gain') }}
          </v-input>

          <v-input icon="divide" v-model.number="state.divide" type="number" step="0.01">
            {{ $t('Divide') }}
          </v-input>
        </div>

        <v-input icon="activity" v-model.number="state.gainOffset" type="number">
          {{ $t('Gain Offset') }}
        </v-input>

        <v-switch v-model="state.invert" label-on="invert" label-off="invert"></v-switch>
      </fieldset>
    </form>
    <template v-slot:footer>
      <ul class="actions">
        <li>
          <button @click="create" @keyup.enter="create" :disabled="!canSubmit" class="btn small success">
            <v-icon name="plus"></v-icon>
            {{ buttonText }}
          </button>
        </li>
      </ul>
    </template>
  </v-panel>
</template>
<script lang="ts" setup>
import { reactive, computed, useAttrs, onMounted } from 'vue'
import { $t } from '/@/i18n'
import { PropType, StateType, EmitType, LinkType } from './types'

const props = defineProps<{ data?: PropType }>()
const attrs = useAttrs()
const emit = defineEmits<{
  (e: 'create-parameter', value: EmitType): void
}>()

const state = reactive<StateType>({
  types: ['input', 'output', 'parameter', 'unknown'],
  type: 'unknown',
  path: '',
  numberOfElements: Infinity,
  channel: 0,
  sim: false,
  gain: 1,
  divide: 1,
  gainOffset: 0,
  invert: false,
})

const hasValidPath = computed<boolean>(() => state.path.length > 5 && state.path.indexOf('/') > -1)
const name = computed<string>(() => state.path.split('/').pop() || '')
const validate = computed<boolean>(() => {
  return Object.entries(state)
    .filter(([key]) => ['channel', 'gain', 'divide', 'gainOffset'].includes(key))
    .every(([_, value]) => !isNaN(value as number))
})

const canSubmit = computed<boolean>(() => hasValidPath.value && validate.value)
const buttonText = computed(() => {
  return attrs.hasOwnProperty('submit-button-label')
    ? attrs['submit-button-label']
    : $t('{t} parameter', { t: props.data !== null ? 'Add' : 'Create' })
})

const create = () => {
  emit('create-parameter', {
    parameter: {
      path: state.path,
      name: name.value,
      type: state.type.toLocaleLowerCase() as LinkType,
      numberOfElements: state.numberOfElements,
      icon: `tree-${state.type.toLocaleLowerCase()}`, // TODO: Deprecate & remove
    },
    sim: state.sim,
    gain: state.gain,
    divide: state.divide,
    gainOffset: state.gainOffset,
    invert: state.invert,
    channel: state.channel,
  })
}

onMounted(() => {
  if (props.data) {
    Object.assign(state, {
      ...props.data,
      channel: props.data.channel || 0,
    })
  }
})
</script>
<style src="./style.css"></style>
