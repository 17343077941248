export default class Excludable {
  public included: boolean = true

  public constructor(...args: any[]) {
    this.processData(args)
  }

  public get isIncluded() {
    return this.included
  }

  public processData(data: any) {
    if (Array.isArray(data)) {
      data = data.flat(3).at(0)
    }

    if (data.included !== undefined) {
      this.included = data.included
    }
  }
}
