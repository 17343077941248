import { removeNumbersFromString } from '../../utils'

export enum DataSignature {
  INT = 'int',
  UINT = 'uint',
  FLOAT = 'float',
  OCTET_ARRAY = 'octet array',
}

export type SignatureKey = keyof typeof DataSignature

export const getKey = (signature: DataSignature) => {
  return Object.keys(DataSignature).find(
    (key) => DataSignature[key as keyof typeof DataSignature] === signature,
  ) as DataSignature
}

export const findByString = (dataType: string = 'OCTET_ARRAY') => {
  const key = removeNumbersFromString(dataType).toUpperCase() as SignatureKey
  return DataSignature[key]
}

export const isBitlengthValid = (signature: DataSignature, bitLength: number) => {
  const bitlengths = getBitLengths(signature)

  if (bitlengths === Infinity) {
    return true
  }

  return (bitlengths as ReadonlyArray<number>).includes(bitLength)
}

export const getBitLengths = (signature: DataSignature) => {
  if ([DataSignature.INT, DataSignature.UINT].includes(signature)) {
    return integerBitLengths
  }

  if (DataSignature.FLOAT === signature) {
    return floatBitLengths
  }

  return Infinity
}

export const isChangeLengthAllowed = (signature: DataSignature) => {
  const bitLengthOptions = getBitLengths(signature)
  if (Array.isArray(bitLengthOptions) && bitLengthOptions.length === 1) {
    return false
  }
  return true
}

export const makeTypeString = (signature: DataSignature, bitLength: number) => {
  if (DataSignature.OCTET_ARRAY === signature) {
    return getKey(signature) as string
  }

  return `${getKey(signature)}${bitLength}`
}

export const integerBitLengths: ReadonlyArray<number> = [8, 16, 32, 64]
export const floatBitLengths: ReadonlyArray<number> = [32]
