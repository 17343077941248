import { getModule } from 'vuex-module-decorators'
import store from './'
import appModule from './modules/app'
import ethercatModule from './modules/ethercat'

export const getAppModule = () => getModule(appModule, store)
export const getEthercatModule = () => getModule(ethercatModule, store)

export const getEthercatConnector = () => {
  return getAppModule().ethercatConnector
}
