import Vue from 'vue'
// Import global styles
import '@vectioneer/ui/style.css'

// Import plugin
import { Bootstrap } from '@vectioneer/ui'

// Use plugin
Vue.use(Bootstrap)

// NProgress
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

NProgress.configure({ showSpinner: false })
