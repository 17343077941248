<template>
  <v-modal name="parameters" @callback="setLinkableProperty" @modalHide="clearLinkableProperty" :keep-alive="true">
    <v-panel class="v-parameters">
      <template v-slot:header>
        <div class="flex flex-center space-between">
          <h4>
            <v-icon name="sliders"></v-icon>
            {{ $t('Parameters') }}
          </h4>

          <ul class="actions">
            <li>
              <button class="small secondary rounded" @click="modal.show('parameter-edit')">
                <v-icon name="plus"></v-icon>
                {{ $t('Add Manually') }}
              </button>
            </li>
            <li>
              <button class="small secondary icon rounded" @click="connect" v-if="isConnectionInErrorState">
                <v-icon name="refresh-cw"></v-icon>
                {{ $t('Retry connecting') }}
              </button>
            </li>
          </ul>
        </div>
      </template>
      <template v-slot:default>
        <template v-if="canConnect && hasTree">
          <div class="search">
            <fieldset>
              <v-input
                icon="search"
                :placeholder="$t('Search')"
                class="small"
                icon-position="right"
                v-model="searchQuery"
                :delay="500"
              ></v-input>
            </fieldset>
          </div>

          <v-tree
            :nodes="nodes"
            :is-searching="hasSearchQuery"
            :emit-on-click="true"
            @node-selected="selectNode"
            v-if="hasTree"
          ></v-tree>
        </template>
        <template v-else>
          <div class="no-connection">
            <v-icon name="wifi-off"></v-icon>
            <h4>{{ $t('Connection Failed') }}</h4>
            <h6>{{ $t('Check your connection details') }}</h6>

            <button class="primary" @click="modal.show('connection')">{{ $t('Connection Details') }}</button>
            <small v-if="isConnecting">
              <span class="badge primary">
                <v-loader></v-loader>
                {{ $t('attempting to reconnect') }}
              </span>
            </small>
          </div>
        </template>
      </template>
    </v-panel>
    <v-modal name="parameter-edit" @modalHide="clearNode">
      <v-create @create-parameter="addLinkedParameter" :data="selectedNode"></v-create>
    </v-modal>
  </v-modal>
</template>
<script lang="ts" setup>
import { $t } from '/@/i18n'
import { NodeType } from '/@/shared/actions/SearchMotorcortexTree'
import { computed, nextTick, ref } from 'vue'
import { useMotorcortex } from '/@/shared/composables/motorcortex'
import { useModal } from '@vectioneer/ui'
import { useStore } from '/@/shared/composables/store'
import { Entry } from '/@/shared/entities/Entry'
import { Mapping } from '/@/shared/entities/Mapping'
import vCreate from './create/index.vue'

const modal = useModal()
const { ethercat } = useStore()
const { connect, canConnect, hasTree, tree, search, isConnectionInErrorState, isConnecting } = useMotorcortex()

const searchQuery = ref<string>('')
const selectedNode = ref<any>(null)
const linkableProperty = ref<Entry | Mapping | null>(null) // TODO: type

const hasSearchQuery = computed(() => searchQuery.value.length > 0)
const nodes = computed(() => (hasSearchQuery.value ? search(searchQuery.value) : tree.value?.children.at(0)))

const addLinkedParameter = (data: any) => {
  // TODO: type
  ethercat.addLinkedParameter({
    item: linkableProperty.value,
    ...data,
  })

  modal.hide('parameter-edit')
  modal.hide('parameters')
}

const setLinkableProperty = (property: Entry | Mapping | null) => {
  linkableProperty.value = property
}

const clearLinkableProperty = () => {
  setLinkableProperty(null)
}

const selectNode = (event: { node?: NodeType; info: any; channel?: any }) => {
  selectedNode.value = {
    ...event.info,
    gain: 1,
    divide: 1,
    gainOffset: 0,
    channel: event.channel ?? null,
  }

  nextTick(() => modal.show('parameter-edit'))
}

const clearNode = () => {
  selectedNode.value = null
}
</script>
<style src="./style.css"></style>
