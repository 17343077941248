import { PDO } from '/@/shared/entities/PDO'
import { EthercatDevice } from '/@/shared/entities/EthercatDevice'
import { convertHexToNumber } from '../utils'
import { areTheSameHexValue } from '/@/shared/utils'

export class ExcludeSwitcher {
  public device: EthercatDevice
  public on: PDO
  public off: PDO[] = []

  constructor(device: EthercatDevice, pdo: PDO, excludes: PDO[]) {
    this.device = device
    this.on = pdo
    this.off = excludes
  }

  public get relatedPdos() {
    return [this.on].concat(this.off)
  }

  public get excludedBy() {
    return this.relatedPdos.find((pdo) => pdo.included)?.index
  }

  public get directOffItem() {
    return this.off[0]
  }
}

export const excludeSwitcherFactory = (device: EthercatDevice): ExcludeSwitcher[] => {
  const excludes = device.pdos //
    .filter((pdo) => pdo.excludes.length > 0)
    .map((pdo) => {
      return {
        pdo,
        excludes: pdo.excludes.map((index) => device.pdos.find((pdo) => areTheSameHexValue(pdo.index, index))) as PDO[],
      }
    })
    .map((item) => new ExcludeSwitcher(device, item.pdo, item.excludes))

  return excludes
}
