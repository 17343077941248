import Adapter from './Adapter'
import { getEthercatConnector } from '/@/store/helpers'

class ScanAdapter extends Adapter {
  async process(host: string) {
    const response = await getEthercatConnector().scanBus()
    this.content = response.data

    return await this.getDevices()
  }
}

export default ScanAdapter
