import { parseStringPromise } from 'xml2js'
import { firstCharLowerCase, parseBooleans } from 'xml2js/lib/processors'
import {
  extractSyncManagers,
  extractType,
  extractPDOs,
  extractModules,
  extractSlots,
  extractMailbox,
} from './extractors'
import { Description } from './interfaces'
import { updateHexSymbol, changePdosNameToGroup } from '../utils'
import { parseIndex } from './processors'
import HexId from '../values/HexId'

class DeviceDescription {
  static async import(xmlData: string) {
    // TODO: Handle version strings (and older versions)
    const result = await parseStringPromise(xmlData, {
      tagNameProcessors: [firstCharLowerCase],
      attrNameProcessors: [firstCharLowerCase],
      valueProcessors: [parseBooleans, parseIndex],
      attrValueProcessors: [parseBooleans],
      trim: true,
      explicitArray: false,
    })

    return this.formatDescriptions(result)
  }

  static formatDescriptions(result: any): Description[] {
    let deviceData = result.etherCATInfo.descriptions.devices.device

    const vendor = result.etherCATInfo.vendor
    const modules = extractModules(result.etherCATInfo.descriptions?.modules)

    if (!Array.isArray(deviceData)) {
      deviceData = [deviceData]
    }

    return deviceData
      .filter((device: any) => device.$ !== undefined && device.$.invisible !== 1)
      .map((device: any) => {
        const hideTypes = this.extractHideType(device)
        const syncManagers = extractSyncManagers(device.sm)
        const type = extractType(device.type)
        const slots = extractSlots(device, [...modules])
        const mailbox = extractMailbox(device.mailbox)

        let rxPdos = changePdosNameToGroup(extractPDOs(device.rxPdo, 'input'))
        let txPdos = changePdosNameToGroup(extractPDOs(device.txPdo, 'output'))

        /** Extract pdos from default module if they're empty and has slots */
        if ([rxPdos, txPdos].every((pdos) => pdos.length === 0) && slots.length > 0) {
          const defaultModules = slots.map((slot: any) => slot.defaultModule)

          rxPdos = defaultModules.map((defaultModule: any) => defaultModule.rxPdo).flat(2)
          txPdos = defaultModules.map((defaultModule: any) => defaultModule.txPdo).flat(2)
        }

        /**
         * Remove slashes from name,
         * motorcortex uses this to create folder structures
         */
        const name = this.extractName(device).replace(/\//g, ' ')

        return {
          hideTypes,
          vendorId: String(HexId.make(vendor.id)),
          name,
          syncManagers,
          type,
          rxPdos,
          txPdos,
          mailbox,
        }
      })
  }

  static extractName(device: any) {
    const name = device.name

    if (Array.isArray(name)) {
      return name[0]._
    }

    if (typeof name === 'string' || name instanceof String) {
      return name
    }

    if (typeof name === 'object' || name instanceof Object) {
      return name._
    }

    return null
  }

  static extractHideType(device: any) {
    if (!device.hasOwnProperty('hideType')) {
      return null
    }

    let hideType = device.hideType

    if (!Array.isArray(hideType)) {
      hideType = [hideType]
    }

    return hideType.map((type: any) => {
      if (typeof type === 'string') {
        return type
      }

      const { revisionNo, productRevision } = type.$
      const values = [revisionNo, productRevision].filter((v) => v !== undefined && v !== null)

      if (!values.length) {
        return 'revision number not found.'
      }

      return updateHexSymbol(values[0])
    })
  }
}

export default DeviceDescription
