import { esi } from '../api'
import { EthercatDevice } from '../entities/EthercatDevice'
import { fetchDescriptions, findDevicesByType } from '/@/shared/worker-helpers'
import { Description } from '/@/shared/importers/interfaces'
import Base from './Base'
import NProgress from 'nprogress'
import { empty } from '/@/shared/utils'

class SearchAndProcessDescriptions extends Base {
  private devices: EthercatDevice[] = []

  private constructor(devices: EthercatDevice[]) {
    super()
    this.devices = devices
  }

  private async process() {
    if (!this.app.isLoggedIn) {
      return
    }

    NProgress.start()

    const results = (await esi.search(this.devices)).data.data

    if (results.some((description) => description.esis.length > 1)) {
      throw new Error('multiple ESI per description not implemented yet.')
    }

    const descriptions = ((await fetchDescriptions(results)) as Description[]) ?? []
    const devices = descriptions
      .map((description) => {
        const devices = findDevicesByType(description.type, this.devices)

        if (!devices.length) {
          return
        }

        devices.forEach((device) => this.ethercat.applyDeviceDescription({ device, description }))
        return devices
      })
      .filter((devices) => !empty(devices))
      .flat()

    NProgress.done()

    return devices
  }

  public static run(devices: EthercatDevice[]) {
    const instance = new SearchAndProcessDescriptions(devices)
    return instance.process()
  }
}

export default SearchAndProcessDescriptions
