import ConfigurationAdapter from '/@/shared/adapters/device-list/ConfigurationAdapter'
import { ConnectionParameters } from '/@/shared/ConnectionParameters'
import { getEthercatConnector } from '/@/store/helpers'

class ScanAdapter extends ConfigurationAdapter {
  async process(source: ConnectionParameters) {
    const response = await getEthercatConnector().getConfig()
    this.content = response.data

    return await this.getDevices()
  }
}

export default ScanAdapter
