import { SyncManager as SyncManagerDataInterface } from '../importers/interfaces'

export class SyncManager {
  public index: number = -1
  public enable: boolean = false
  public startAddress: string = ''
  public controlByte: string = ''
  public defaultSize: number = 0
  public name: string = ''

  constructor(data?: SyncManagerDataInterface) {
    if (data) {
      this.index = data.index

      if (data.enable) {
        this.enable = data.enable
      }

      if (data.startAddress) {
        this.startAddress = data.startAddress
      }

      if (data.controlByte) {
        this.controlByte = data.controlByte
      }

      if (data.defaultSize) {
        this.defaultSize = data.defaultSize
      }

      if (data.name) {
        this.name = data.name
      }
    }
  }
}
